import React, { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Menu from "./components/Menu";
import SuspenseRoute from "./components/SuspenseRoute";
import "./App.scss";

//Views
const HomePage = React.lazy(() => import("./views/home"));
const BookPage = React.lazy(() => import("./views/book"));
const SalonsPage = React.lazy(() => import("./views/salons"));
const AproposPage = React.lazy(() => import("./views/apropos"));
const PageNotFound = React.lazy(() => import("./views/pagenotfound"));
const Legal = React.lazy(() => import("./views/legal"));

const App = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <BrowserRouter>
        <Menu closeMenu={() => setOpen(false)} open={open} />
        <Header openMenu={() => setOpen((a) => !a)} open={open} />
        <div
          className={"main noscrollbar " + (open ? "pushed" : "")}
          onClick={() => open && setOpen(false)}
          onTouchStart={() => open && setOpen(false)}
        >
          <Switch>
            <SuspenseRoute path={["/", "/home"]} exact component={HomePage} />
            <SuspenseRoute
              path="/roman/:bookNameUrl"
              exact
              component={BookPage}
            />
            <SuspenseRoute
              path={"/jeunesse/:bookNameUrl"}
              exact
              component={BookPage}
            />
            <SuspenseRoute path="/salons" exact component={SalonsPage} />
            <SuspenseRoute path="/a-propos" exact component={AproposPage} />
            <SuspenseRoute path="/mentions-legales" exact component={Legal} />
            <SuspenseRoute path="/404" component={PageNotFound} />
            <Route path="*">
              <Redirect to="/404" />
            </Route>
          </Switch>
        </div>
        <div id="popup-root" />
      </BrowserRouter>
    </>
  );
};

export default App;
