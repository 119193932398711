import React, { useState, useEffect } from "react";
import "./header.scss";
import { useHistory } from "react-router-dom";
import CONTENT from "../../content.json";
import { UpperFirst } from "../../helpers/utils";

const Header = ({ open, openMenu }) => {
  const history = useHistory();
  const [type2, setType2] = useState(
    !["/", "/home"].includes(history.location.pathname)
  );
  useEffect(() =>
    history.listen(() => {
      document.querySelector(".main").scrollTo({ top: 0 });
      setType2(!["/", "/home"].includes(history.location.pathname));
    })
  );
  const pageOrScroll = () => {
    if (history.location.pathname === "/")
      return document
        .querySelector(".main")
        .scrollTo({ top: 0, behavior: "smooth" });
    history.push("/");
  };
  return (
    <header
      className={
        "fixed " /* + (!type1 && type2 ? "shadow " : "")*/ +
        (type2 ? "dark" : "")
      }
    >
      <button onClick={openMenu}>
        <img
          src={"/img/MenuIcon" + (!type2 ? "-light" : "") + ".svg"}
          width="28"
          alt="menu"
        />
        <h3>{UpperFirst(CONTENT.header.menu)}</h3>
      </button>
      <h1 onClick={pageOrScroll}>{UpperFirst(CONTENT.header.titre)}</h1>
      <button onClick={() => window.open("mailto:vewonik@nuiro.me", "_self")}>
        <h3>{UpperFirst(CONTENT.header.contact)}</h3>
        <img
          src={"/img/ContactIcon" + (!type2 ? "-light" : "") + ".svg"}
          width="18"
          alt="mail"
        />
      </button>
    </header>
  );
};

export default Header;
