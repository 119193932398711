import React, { Suspense } from "react";
import { Route } from "react-router-dom";

const STYLE = {
  height: "100vh",
  width: "100vw",
  zIndex: 1000,
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "white"
};
const SuspenseRoute = (props) => {
  let { fallback, component: Component } = props;
  let newProps = { ...props };
  delete newProps.fallback;
  delete newProps.component;
  console.log("SuspenseRoute", props);
  const countDisable = sessionStorage.getItem("countDisable") === "true";
  if (props.path !== "*" && props.path !== "/404" && !countDisable) {
    console.log("couting visit");
    sessionStorage.setItem("countDisable", true);
    fetch("https://api.countapi.xyz/hit/oriun/khnoum_visits");
  }
  return (
    <Route {...newProps}>
      <Suspense fallback={fallback || <div style={STYLE}></div>}>
        <Component {...newProps} />
      </Suspense>
    </Route>
  );
};
export default SuspenseRoute;
